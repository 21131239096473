<template>
  <v-card transparent>
    <v-card-text>
      <v-row dense class="smaller mb-0 pb-0">
        <v-col class="pa-0">
          <v-text-field
            outlined
            hide-details
            dense
            prepend-icon="mdi-magnify"
            aut-role-search
            placeholder="Search"
            clearable
            v-model="searchValue"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0 pb-0" dense>
        <list-field
          label="Role"
          :list="filteredRoles"
          :value="selectedRole"
          max_height="140"
          @select="selectedRole = $event"
        >
        </list-field>
        <v-col cols="12" md="9" class="pa-2">
          <v-container>
            <label v-if="!selectedRole">Choose a role on the left</label>
            <div v-else>
              <!-- {{selectedRoleObject}} -->
              <v-switch
                aut-role-sidebar-toggle
                dense
                v-model="selectedRoleObject.settings.sidebar.display"
                :disabled="isDisabled"
                label="Display Sidebar"
                @change="updatePreferences"
              ></v-switch>

              <v-text-field
                outlined
                dense
                aut-role-home-page
                v-model="selectedRoleObject.settings.home_page"
                label="Home Page"
                v-on:keydown.enter.stop.prevent="updatePreferences"
                @blur="updatePreferences"
                append-icon="mdi-navigation-variant"
                @click:append="
                  navigateToPage(selectedRoleObject.settings.home_page)
                "
              />
              <v-text-field
                aut-role-profile-page
                v-model="selectedRoleObject.settings.profile_page"
                label="Profile Page"
                v-on:keydown.enter.stop.prevent="updatePreferences"
                @blur="updatePreferences"
                append-icon="mdi-navigation-variant"
                @click:append="
                  navigateToPage(selectedRoleObject.settings.profile_page)
                "
              />
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="updateSkin" color="primary">Update Roles</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { clone } from "@/util";
import { defaultsDeep } from "lodash";
import { mapGetters, mapActions } from "vuex";
const debug = require("debug")("atman.settings.role_based_settings"); // eslint-disable-line
export default {
  name: "RoleBasedSettings",
  props: {
    definition: {
      type: Object,
    },
  },
  components: {},
  data() {
    return {
      roles: [],
      searchValue: "",
      selectedRole: null,
      skin: clone(this.definition),
    };
  },
  computed: {
    ...mapGetters(["isClassicLayout"]),
    filteredRoles() {
      let result = [...this.roles];
      if (this.searchValue) {
        // TODO lowercase
        result = result.filter(
          (role) =>
            role.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) !=
            -1
        );
      }
      return result;
    },
    isDisabled() {
      return !this.isClassicLayout;
    },
    selectedRoleObject() {
      if (!this.selectedRole) {
        return null;
      }
      return this.roles.find((role) => {
        return role.id == this.selectedRole;
      });
    },
    display() {
      let result = false;
      if (!this.isClassicLayout) {
        result = false;
      } else {
        result = this.definition?.sidebar?.display || false;
      }
      return result;
    },
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    navigateToPage(path) {
      if (!path) {
        return;
      }
      this.$router.push({ path: path });
    },
    ...mapActions(["saveSettingsToDomain"]),
    updateSkin() {
      this.saveSettingsToDomain(this.skin);
    },
    updatePreferences() {
      debug(`updatePreferences invoked`, this.selectedRoleObject);
      let skin = clone(this.skin);
      skin.roles = skin.roles || {};
      skin.roles[this.selectedRoleObject.id] = this.selectedRoleObject.settings;
      debug(`skin`, skin);
      this.skin = skin;
    },
    async getRoles() {
      const component = this;
      const roles = await this.$store.dispatch("fetchRoles");
      const rolesWithSidebar = roles.map((item) => {
        const roleSettings = component.skin?.roles?.[item.id] || {};
        let shouldDisplaySidebar = roleSettings.sidebar?.display;
        if (typeof shouldDisplaySidebar == "undefined") {
          shouldDisplaySidebar = true;
        }
        let defaultHomePage = roleSettings.home_page || "/";
        let defaultProfilePage = roleSettings.profile_page || "/me";
        defaultsDeep(
          item,
          {
            settings: {
              sidebar: {
                display: shouldDisplaySidebar,
              },
              home_page: defaultHomePage,
              profile_page: defaultProfilePage,
            },
          },
          { label: item.name }
        );
        item.label = item.name;
        return item;
      });
      this.roles = rolesWithSidebar;
      debug(`Roles`, this.roles);
    },
  },
};
</script>
